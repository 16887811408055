import Card1 from "./projectCard1.component";
import Card2 from "./projectCard2.component";
import Card3 from "./projectCard3.component";
import Card4 from "./projectCard4.component";
import { motion } from "framer-motion";

const Projects = () => {
  return (
    <div className="flex flex-col w-100 justify-center items-center p-4">
      <h1 className="text-primary font-bold text-6xl p-8 text-center">
        A little of my work.
      </h1>
      <motion.div
        initial={{
          opacity: 0,
          y: -50,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        className="flex flex-row flex-wrap gap-4 justify-center"
      >
        <Card1 />
        <Card2 />
        <Card3 />
        <Card4 />
      </motion.div>
    </div>
  );
};

export default Projects;
