import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Tooltip,
  } from "@material-tailwind/react";
  import ProducerDashImg from '../../assets/PDU.jpg'


   
  export default function Card1() {
    return (
      <Card className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 w-full md:w-96">
        <CardHeader floated={false} className="h-50">
          <img src={ProducerDashImg} alt="profile" />
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h4" color="blue-gray" className="mb-2">
            Producer Dashboard
          </Typography>
          <Typography color="blue" className="font-medium" textGradient>
            <a href="https://www.writabeats.com/" target='_blank' rel="noreferrer">Visit Here</a>
          </Typography>
        </CardBody>
        <CardFooter className="flex justify-center gap-7 pt-2">
          <Tooltip content="Like">
            <Typography
              as="a"
              href="#facebook"
              variant="lead"
              color="blue"
              textGradient
            >
              <i className="fab fa-facebook" />
            </Typography>
          </Tooltip>
          <Tooltip content="Follow">
            <Typography
              as="a"
              href="#twitter"
              variant="lead"
              color="light-blue"
              textGradient
            >
              <i className="fab fa-twitter" />
            </Typography>
          </Tooltip>
          <Tooltip content="Follow">
            <Typography
              as="a"
              href="#instagram"
              variant="lead"
              color="purple"
              textGradient
            >
              <i className="fab fa-instagram" />
            </Typography>
          </Tooltip>
        </CardFooter>
      </Card>
    );
  }