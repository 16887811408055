import { Button } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import { Textarea } from "@material-tailwind/react";
import { useState } from "react";
import axios from "axios";

import InstaColor from "../../assets/instagramLogoColor.png";
import TwitterColor from "../../assets/twitterLogoColor.png";
import GitHubColor from "../../assets/githubLogoColor.png";
import LinkedInColor from "../../assets/linkedinLogoColor.png";

const API_URL = "https://personal-portfolio-server-orpin.vercel.app";
const defaultFormFields = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const Contact = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { name, email, subject, message } = formFields;
  const [successMessage, setSuccessMessage] = useState(undefined);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleContactSubmit = (event) => {
    event.preventDefault();
    const requestBody = { name, email, subject, message };

    axios
      .post(`${API_URL}/api/contact`, requestBody)
      .then((response) => {
        console.log(response);
        setSuccessMessage(response.data);
        resetFormFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col items-center p-2">
      <section className="dark:bg-primary-800 dark:text-primary-50 lg:p-16">
        <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
          <div className="py-6 md:py-0 md:px-6">
            <h1 className="text-4xl text-center text-primary font-bold pb-2">
              Get in touch
            </h1>
            <hr />
            <p className="pt-2 pb-4 text-primary">
              Fill in the form to start a conversation
            </p>
            <div className="space-y-4">
              <p className="flex items-center text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                </svg>
                <a href="sms:+1407-724-4971">407-724-4971</a>
              </p>
              <p className="flex items-center text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                <a href="mailto:zacjohnsoncontact@proton.me">
                  zacjohnsoncontact@proton.me
                </a>
              </p>
              <div className="flex justify-center items-center w-100 mt-4">
                <div className="flex flex-row space-x-4 items-center">
                  <a
                    href="https://twitter.com/zacjjohnson"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-8 object-cover"
                      src={TwitterColor}
                      alt="twitter logo"
                    ></img>
                  </a>
                  <a
                    href="https://www.instagram.com/zachariahjjohnson/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-8 object-cover"
                      src={InstaColor}
                      alt="instagram logo"
                    ></img>
                  </a>
                  <a
                    href="https://github.com/zacjjohnson"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-8 object-cover"
                      src={GitHubColor}
                      alt="twitter logo"
                    ></img>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/zacjjohnson/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-8 object-cover"
                      src={LinkedInColor}
                      alt="twitter logo"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleContactSubmit}
            className="flex flex-col py-6 space-y-6 md:py-0 md:px-6 ng-untouched ng-pristine ng-valid"
          >
            <label className="block">
              <Input
                type="text"
                label="Name"
                name="name"
                value={name}
                onChange={handleChange}
                className="max-w rounded-md text-white-500"
              />
            </label>
            <label className="block">
              <Input
                type="text"
                label="Email"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full max-w rounded-md"
              />
            </label>
            <label className="block">
              <Textarea
                label="Message"
                name="message"
                className="text-white"
                value={message}
                onChange={handleChange}
              />
            </label>
            <Button className="bg-primary" type="submit">
              Submit
            </Button>

            <div className="w-auto">
              {successMessage && (
                <h3 className="text-center text-bold">{successMessage}</h3>
              )}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
