import { motion } from "framer-motion";
import Balancer from "react-wrap-balancer";

const HomePage = () => {
  return (
    <>
      <div className="flex flex-col min-h-[50vh] w-full items-center justify-center">
        <motion.div
          initial={{
            opacity: 0,
            y: -50,
          }}
          animate={{
            opacity: 1,
            y: 25,
          }}
        >
          <motion.h1 className="mx-auto text-center text-4xl md:text-6xl lg:text-7xl font-display font-bold tracking-[-0.02em] text-transparent drop-shadow-sm md:leading-[5rem]">
            <Balancer>
              <span className="text-primary inline-block text-transparent bg-clip-text">
                My name is Zac, I build websites
              </span>
            </Balancer>
          </motion.h1>
        </motion.div>

        <div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
