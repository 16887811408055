import { motion } from "framer-motion";


const AboutMe = () => {
  return (
    <motion.div
        initial={{
          opacity: 0,
          y: -50,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        className="flex flex-row flex-wrap gap-4 justify-center"
      >
        
    <div className="flex flex-col justify-center">
      <h1 className="text-primary justify-center font-bold text-4xl p-8 text-center">
        About Me
      </h1>

      <p className="lg:text-xl sm:text-xs sm:w-100 p-8 justify-center text-primary text-center w-100 leading-10">
        Hi there! My name is Zac and I am a 29-year-old full stack engineer.
        <br />
        I currently work as a Digital Sales Representative at Openhouse Home
        Insurance. I have spent the majority of my career in a sales position
        and have gained invaluable skills in these roles. I love working with
        new people, workshopping new ideas, and aligning team goals to achieve
        something great. I have experience with cold calling, account
        management, inside sales, B2B, DTC, Insurance Sales, customer service,
        CRM's, and outside sales among other skills I've picked up along the
        way. I've spent the last year and a half learning Software Development,
        Computer Science core competencies, and spend every free moment moving
        towards my goal of becoming the best programmer I can be. Full Stack
        Development has completely changed my life, in my free time I now find
        myself solving LeetCode problems, building websites for friends, and
        researching anything and everything to do with computer science.
        <br />
        <br />
        On top of my professional goals and achievements, I'm a dad to two
        incredible boys. One is 3 years old and the other is 3 weeks old! In my
        spare time I'm a superhero, a wrestler, and anything else my 3 year old
        wants me to be. I love to hike, travel, and spend quality time with my
        wife and kids. I love meeting new people and sharing new ideas. Feel
        free to reach out to me anytime!
        <br />
        <br />
        Competencies: MongoDb, Javascript, NodeJS, React.Js, Express, HTML, CSS,
        API's, Git, GitHub, Agile Methodologies
        <br /> <br />
        Thank you for visiting my website!
      </p>
    </div>
      </motion.div>
  );
};

export default AboutMe;
