import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function Example() {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const handleLinkClick = () => {
    setOpenNav(false);
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 sm:text-primary">
      <Link
        onClick={handleLinkClick}
        to="/projects"
        className="flex items-center text-primary font-bold text-2xl"
      >
        Projects
      </Link>

      <Link
        onClick={handleLinkClick}
        to="/about"
        className="flex text-primary items-center font-bold text-2xl"
      >
        About Me
      </Link>

      <Link
        onClick={handleLinkClick}
        to="/music"
        className="flex items-center text-primary font-bold text-2xl"
      >
        Music
      </Link>

      <Link
        onClick={handleLinkClick}
        to="/contact"
        className="flex items-center text-primary font-bold text-2xl"
      >
        Contact
      </Link>
    </ul>
  );

  return (
    <>
      <Navbar className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4 bg-nuetral">
        <div className="container mx-auto text-primary flex flex-row items-center justify-between">
          <Typography
            as="a"
            href="/"
            variant="small"
            className="mr-4 cursor-pointer py-1.5 font-bold text-2xl"
          >
            <span>Zachariah Johnson</span>
          </Typography>
          <div className="hidden lg:block text-primary">{navList}</div>

          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-primary hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          <div className="container mx-auto">{navList}</div>
        </MobileNav>
      </Navbar>
    </>
  );
}
