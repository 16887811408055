import "./index.css";
import { Routes, Route } from "react-router-dom";

import Navigation from "./components/navbar/navbar.component";
import Projects from "./components/projects/project.component";
import AboutMe from "./components/about/aboutme.component";
import Contact from "./components/contact/contact.component";
import Music from "./components/music/music.component";
import { useEffect } from "react";
import axios from "axios";
import HomePage from "./components/homepage/homepage.component";

const API_URL = "https://www.zachariah.dev";

function App() {
  useEffect(() => {
    axios.get(`${API_URL}/api`).then((response) => {
      console.log(response);
    });
  }, []);


  return (
    <div className="App w-screen min-h-screen bg-gradient-to-r from-slate-400 to-red-600">
      <Navigation />

      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/projects" element={<Projects />} />

        <Route path="/about" element={<AboutMe />} />

        <Route path="/contact" element={<Contact />} />

        <Route path="/music" element={<Music />} />
      </Routes>
    </div>
  );
}

export default App;
