const Music = () => {
  return (
    <>
      <div
        className="hero min-h-screen"
        style={{
          backgroundImage:
            "url(https://i.imgur.com/Z0CnDi2.jpeg)",
        }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="flex flex-row gap-4 w-3/4 justify-center bg-grey">
          <div>
          <iframe className="rounded-lg w-full" src="https://open.spotify.com/embed/artist/436n7Ut7vqLxKqJiVipZWo?utm_source=generator&theme=0" width="100%" height="352" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div>
          <iframe className="rounded-lg w-full" src="https://open.spotify.com/embed/artist/6htzKwgf1dusJjw118SKI5?utm_source=generator" height="352" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Music;
